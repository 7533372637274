<template>
  <v-card tag="section" class="py-4 px-4 mt-4">
    <v-card-title>Send email</v-card-title>
    <v-form
      ref="form"
      @submit.prevent="submit"
    >
      <v-text-field
        class="mb-2"
        v-model="payload.subject"
        autofocus
        label="Subject"
        required
        :error-messages="errors.fieldErrors.subject"
      ></v-text-field>
      <v-text-field
        class="mb-2"
        v-model="payload.reply_to"
        label="From email"
        type="email"
        required
        :error-messages="errors.fieldErrors.reply_to"
      ></v-text-field>

      <v-textarea
        class="mb-2"
        outlined
        v-model="payload.content"
        label="Content"
        required
        rows="10"
        :auto-grow="true"
        :error-messages="errors.fieldErrors.content || []"
      ></v-textarea>
      <h3>Recipients</h3>

      <autocomplete-select
        apiPath="admin/clients"
        textField="name"
        :multiple="true"
        v-model="payload.filters.client"
        label="Communities"
      ></autocomplete-select>
      <v-checkbox
        v-model="payload.filters.role"
        value="client-admin"
        label="Community Admin"
      ></v-checkbox>
      <list-field
        v-model="payload.emails"
        label="Additional recipients (coma separated)"
        :error-messages="errors.fieldErrors.emails || []"
      ></list-field>

      <v-btn
        color="success"
        type="submit"
        class="mt-4"
        :loading="isLoading"
        :disabled="isLoading"
      >
        Send to {{ recipients.length }} recipients
      </v-btn>
    </v-form>
    <v-textarea
        class="mt-4"
        outlined
        :value="recipients.join(', ')"
        label="Recipients list"
        required
        readonly
        rows="10"
      ></v-textarea>
  </v-card>
</template>

<script>
import http from '@/http'
import ListField from '@/components/ListField'
import AutocompleteSelect from '@/components/AutocompleteSelect'

export default {
  props: [],
  components: {
    ListField,
    AutocompleteSelect,
  },
  data () {
    return {
      errors: {fieldErrors: {}, nonFieldErrors: {}},
      isLoading: false,
      matchingAccounts: 0,
      recipients: [],
      payload: {
        subject: "",
        content: "",
        reply_to: "altacalltsm@tsm-education.fr",
        emails: [],
        filters: {
          role: [],
          client: [],
        },
      }
    }
  },
  methods: {
    async submit () {
      this.isLoading = true
      this.errors = {fieldErrors: {}, nonFieldErrors: {}}
      try {
        await http.post('admin/email', {...this.payload, send: true})
      } catch (e) {
        this.errors = e.backendErrors
      } finally {
        this.isLoading = false
      }
    },
    async fetchRecipients () {
      this.isLoading = true
      this.recipients = []
      try {
        let response = await http.post('admin/email', {...this.payload, send: false})
        this.recipients = response.data.emails
      } catch (e) {
        this.errors = e.backendErrors
      } finally {
        this.isLoading = false
      }
    }
  },
  watch: {
    "payload.filters": {
      async handler (v) {
        await this.fetchRecipients(v)
      },
      deep: true,
    }
  }
}
</script>
